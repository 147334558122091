import React from "react"
import "./timely.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav,NavDropdown } from "react-bootstrap";
import watch from "../images/watch.svg";
import clock from "../images/clock.svg";
import phone from "../images/phone.svg";
import phoneAndroid from "../images/phoneAndroid.svg";
import timely from "../images/timelyGreen.svg";
import telecharger from "../images/telecharger.svg";



const timelyPage=()=>(
    <div>
        <Navbar bg="white" fixed="top" expand="lg">
            <div className="container containerNav">
                <Navbar.Brand href="#">
                    <img src={timely} className="logo"/>
                    Timely
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="navRest">

                        <Nav.Link href="#how" className="btnNav">
                            Comment ça marche
                        </Nav.Link>
                        <Nav.Link href="#why" className="btnNav">
                            Pourquoi l'utiliser
                        </Nav.Link>
                        <Nav.Link href="#download" className="btnNav">
                            Télécharger
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>

        <div className="container" style={{marginTop:"150px"}} >
            <div className="row align-items-center" >
                <div className="col-md-6">
                    <div className="paver1">
                        <h3>Quand est le prochain bus?</h3>
                        <div className="descTimely">
                            La réponse en 2 clics sur votre téléphone grâce à Timely.
                            Optimisez vos déplacements en sachant exactement quand votre bus arrivera.
                        </div>
                        <a href="../timely.apk" download>
                            <div className="btnTelecharger">
                                <img src={telecharger} style={{marginRight:"4px"}}/>
                                Télécharger
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src={clock} />
                </div>
            </div>

            <div className="row" id="how">
                <div className="col-md-12">
                    <div className="petitMot">
                        Comment ça marche
                    </div>
                    <div className="greenBar"></div>
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-md-6">
                    <img src={phone}/>
                </div>
                <div className="col-md-6">
                    <div className="paver1">
                        <div className="explication explication2">
                            Les horraires dans votre poches
                        </div>
                        <div className="fav">
                            Timely cherche les horaires de vos bus et tram depuis les serveurs de la companie de transport strasbourgeoise (CTS).
                            Les horaires sont disponibles en temps réel.<br/>
                            Sauvegardez vos arrêts dans vos favoris pour gagnez encore plus de temps dans vos recherches.
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" id="why">
                <div className="col-md-12" >
                    <div className="petitMot">
                        Pourquoi utiliser Timely ?
                    </div>
                    <div className="greenBar"></div>
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-md-6">
                <div className="paver1">
                    <div className="explication2">
                        Gagner du temps !
                    </div>
                    <div>
                        En un seul click, vous connaissez les horraires des prochains bus et tram.
                        Vous réduisez le temps que vous passez à attendre à l'arrêt.
                    </div>
                </div>
                </div>
                <div className="col-md-6 ">
                    <img src={watch} />
                </div>
            </div>


            <div className="row" id="download">
                <div className="col-md-12">
                    <div className="petitMot">
                        Télécharger
                    </div>
                    <div className="greenBar"></div>
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-md-6">
                    <img src={phoneAndroid}/>
                </div>
                <div className="col-md-6">
                    <div className="paver1">
                        <div className="explication3">
                        Disponible sur Android !
                        </div>
                        <div className="descDl">
                        Tous les horaires à portée de main. Téléchargez Timely tout de suite et ne perdez plus de temps !
                        </div>
                        <a href="../timely.apk" download>
                            <div className="btnTelecharger">
                                <img src={telecharger}  style={{marginRight:"4px"}}/>
                                Télécharger
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
export default timelyPage
